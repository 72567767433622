/* eslint-disable no-unused-vars */
export const generateListDates = (array) => {
  const localarray = [];
  for (const item of array) {
    const exists = localarray.find(dateItem => (
      item.getDate() === dateItem.getDate() &&
      item.getMonth() === dateItem.getMonth() &&
      item.getFullYear() === dateItem.getFullYear()
    ));
    if (!exists) {
      localarray.push(item);
    }
  }
  return localarray;
};

export function convertirMinutosAHoras(minutos) {
  if (minutos < 0) {
    return '00:00';
  }

  // Calcular horas y minutos restantes
  const horas = Math.floor(minutos / 60);
  const minutosRestantes = minutos % 60;

  // Formatear en hh:mm
  const formatoHoras = horas.toString().padStart(2, "0");
  const formatoMinutos = minutosRestantes.toFixed(0).toString().padStart(2, "0");

  return `${formatoHoras}:${formatoMinutos}`;
}

