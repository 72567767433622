import axios from '../config/Axios';

const url = '/api/rushhour';

export const createRushHour = ({
  initialHour, endHour, title, token
}) => new Promise((resolve, reject) => {
  if (title && token) {
    axios.post(`${url}/create`, {
      initialHour, endHour, title
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Verifique los campos'
    });
  }
});

export const updateRushHour = ({
  initialHour, endHour, title, token, _id
}) => new Promise((resolve, reject) => {
  if (title && token && _id) {
    axios.put(`${url}/update/${_id}`, {
      initialHour, endHour, title
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Verifique los campos'
    });
  }
});

export const listRushHour = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const deleteRushHour = ({
  token, _id
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/delete/${_id}`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

