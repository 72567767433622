import axios from '../config/Axios';

const urlContact = '/api/contactqr';

export const addContactQr = ({
  title,
  idCampaign,
  url,
  token,
}) => new Promise((resolve, reject) => {
  if (token && title && idCampaign && url) {
    axios.post(`${urlContact}/add`, {
      title, idCampaign, url
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!title) {
    reject({
      status: 'error',
      info: 'Titulo es requerido'
    });
  } else if (!idCampaign) {
    reject({
      status: 'error',
      info: 'Campaña es requerido'
    });
  } else if (!url) {
    reject({
      status: 'error',
      info: 'URL es requerido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token es requerido'
    });
  }
});

export const oneContactQrAdmin = ({
  _id, token
}) => new Promise((resolve, reject) => {
  if (_id && token) {
    axios.get(`${urlContact}/one/bycampaign/${_id}`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador es requerido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token es requerido'
    });
  }
});

export const viewContactQRPublic = ({
  _id
}) => new Promise((resolve, reject) => {
  if (_id) {
    axios.get(`${urlContact}/view/one/${_id}`).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Identificador es requerido'
    });
  }
});


export const countContactQRAdvertising = ({
  token, idAdverising,
}) => new Promise((resolve, reject) => {
  if (token && idAdverising) {
    axios.get(`${urlContact}/count/${idAdverising}`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data)
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros necesarios'
    });
  }
});