/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { IconButton, Typography } from '@mui/material';
import ChartistGraph, { FixedScaleAxis } from 'react-chartist';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { useParams } from 'react-router-dom';
import { getOneAdvertising } from '../../../api/advertising.api';
import { infoAdvertisingLogCampaign, metricsVisutalizationVideo } from '../../../api/logcampaign.api';
import { countBackEvents, countNextEvents, countPauseEvents } from '../../../api/eventscampaign.api';
import { countContactQRAdvertising, viewContactQRPublic } from '../../../api/contactqr.api';
import 'moment/locale/es-mx';

function createData(time, amount) {
  return { time, amount };
}

const OneAdvertising = ({ token }) => {
  moment.locale('es-mx');
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [initialDate, setInitialDate] = useState(new Date('2024-01-02'));
  const [endDate, setEndDate] = useState(new Date());
  const [incomeExpenseSummaryChartData, setIncomenExpenseSummary] = useState({
    // A labels array that can contain any sort of values
    labels: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    // Our series array that contains series objects or in this case series data arrays
    series: [
      [505, 781, 480, 985, 410, 822, 388, 874, 350, 642, 320, 796]
    ]
  });
  const [totalHour, settotalhour] = useState('0:00');
  const [km, setKm] = useState(0);
  const [info, setInfo] = useState([]);
  const [countNext, setCountNext] = useState(0);
  const [countBack, setCountBack] = useState(0);
  const [countPause, setCountPause] = useState(0);
  const [countQr, setCoutQr] = useState(0);

  let { id } = useParams();

  const loadElements = async () => {
    try {
      const res = await getOneAdvertising({ token, id });
      setData(res.data);
      const resInfoUser = await infoAdvertisingLogCampaign({
        token, idAdverising: id, date1: initialDate, date2: endDate,
      });
      console.log(resInfoUser);
      setInfo(resInfoUser.data.info);
      setKm(resInfoUser.data.km);
      settotalhour(resInfoUser.data.textHour);
    } catch (error) {
      console.log(error);
      toastr.error('Error al obtener los datos');
    }
  }

  const handleDataLogCampaign = async () => {
    try {
      const res = await metricsVisutalizationVideo({
        token,
        queryFirstInitialDate: initialDate,
        queryFirstEndInitalDate: endDate,
        querySecondEndDate: endDate,
        querySecondInitialDate: endDate,
        type: 'advertising',
        idAdverising: id,
      });
      var groupedData = []
      var counter = 0;
      res.data.data1.forEach(element => {
        var dateData = element.createdAt.split('T')[0];
        var year = dateData.split('-')[0]
        var month = dateData.split('-')[1]
        var joint = year + '-' + month

        var temp = groupedData[counter]

        if (temp) {
          if (temp.time === joint) {
            groupedData[counter].amount++;
          } else {
            groupedData.push(createData(joint, 1))
            counter++;
          }
        }
        else {
          groupedData.push(createData(joint, 1))
        }
      });
      // mapeando todos los dias
      var labelTemp = [];
      var dataTemp = [];
      for (let index = 0; index < groupedData.length; index++) {
        labelTemp.push(groupedData[index].time);
        dataTemp.push(groupedData[index].amount);
      }
      setIncomenExpenseSummary({
        // A labels array that can contain any sort of values
        labels: labelTemp,
        // Our series array that contains series objects or in this case series data arrays
        series: [
          dataTemp
        ]
      });
      const resBack = await countBackEvents({
        token,
        idAdverising: id,
      });
      setCountBack(resBack.data);
      const resNext = await countNextEvents({
        token, idAdverising: id
      });
      setCountNext(resNext.data);
      const resPause = await countPauseEvents({
        token, idAdverising: id
      });
      setCountPause(resPause.data);
      const resQr = await countContactQRAdvertising({
        token, idAdverising: id
      });
      setCoutQr(resQr.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadElements();
    handleDataLogCampaign();
  }, [initialDate, endDate]);

  const incomeExpenseSummaryChartOptions = {
    height: 300,
    axisY: {
      high: 1000,
      low: 250,
      referenceValue: 1000,
      type: FixedScaleAxis,
      ticks: [250, 500, 750, 1000]
    },
    showArea: true,
    showPoint: false,
    fullWidth: true
  }

  const incomeExpenseSummaryChartResponsiveOptions = [
    ['screen and (max-width: 480px)', {
      height: 150,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value;
        }
      }
    }]
  ];


  return (
    <div>
      <div className="mb-3 d-flex justify-content-between">
        <IconButton
          onClick={() => {
            navigate('/advertising?page=1&count=10');
          }}
        >
          <span className="material-symbols-outlined">
            arrow_back
          </span>
        </IconButton>
        <button
          className="btn btn-primary"
          onClick={async () => {
            // Capturar el elemento como imagen
            const elemento = document.getElementById('contenido');
            const canvas = await html2canvas(elemento);
            const imgData = canvas.toDataURL('image/png');

            // Crear un documento PDF
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            // Ajustar la imagen al PDF
            const imgWidth = 190;  // Ancho en mm (ajustado para A4)
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

            // Descargar el archivo
            pdf.save('documento.pdf');
          }}
        >
          <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
            Generar PDF
          </Typography>
        </button>
      </div>
      <div id='contenido'>
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-sm-flex align-items-baseline report-summary-header">
                      <h5 className="font-weight-semibold">
                        <b>Titulo: </b> {` ${data.title}`}
                      </h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <DateRangePicker
                      className="ml-auto income-expense-summary-chart-daterange"
                      calendarIcon={<i className='icon-arrow-down'></i>}
                      format="MMMM d, y"
                      clearIcon={null}
                      value={[initialDate, endDate]}
                      onChange={date => {
                        setInitialDate(date[0]);
                        setEndDate(date[1])
                      }}
                    />
                  </div>
                  <div className='col-md-12'>
                    <div className="row report-inner-cards-wrapper">
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">Horas Acumuladas</span>
                          <h4>
                            {totalHour}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">KM Acumulados</span>
                          <h4>
                            {`${km} KM`}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">Cta de Pausa</span>
                          <h4>
                            {countPause}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">Cta de Adelanto</span>
                          <h4>
                            {countNext}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">Cta de Retraso</span>
                          <h4>
                            {countBack}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                      <div className=" col-md -6 col-xl report-inner-card">
                        <div className="inner-card-text">
                          <span className="report-title">Cta de escaneo QR</span>
                          <h4>
                            {countQr}
                          </h4>
                        </div>
                        <div className="inner-card-icon bg-success">
                          <span className="material-symbols-outlined">
                            store
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row income-expense-summary-chart-text">
                  <div className="col-xl-4">
                    <h5>Historial de Visualización de Videos</h5>
                    <p className="small text-muted">
                      Es para que puedan comparar en meses la cantidad de visualizaciones de camapañas en general que tiene
                      la aplicación Dicip Go
                    </p>
                  </div>
                  <div className=" col-md-3 col-xl-2">

                  </div>
                  <div className="col-md-3 col-xl-2">
                  </div>
                  <div className="col-md-6 col-xl-4 d-flex align-items-center">
                  </div>
                </div>
                <div className="row income-expense-summary-chart mt-3">
                  <div className="col-md-12">
                    <div className="card card-body">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Tableta</th>
                              <th>Minutos</th>
                              <th>KM</th>
                            </tr>
                          </thead>
                          <tbody>
                            {info.map(element => (
                              <tr>
                                <td>{element.tablet}</td>
                                <td>{element.item.minutes}</td>
                                <td>{element.item.km}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="ct-chart" id="income-expense-summary-chart"></div>
                    {/* <ChartistGraph
                      type="Line"
                      data={incomeExpenseSummaryChartData}
                      options={incomeExpenseSummaryChartOptions}
                      responsive-options={incomeExpenseSummaryChartResponsiveOptions}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(OneAdvertising);
