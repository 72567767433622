/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import './Campaign.css';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { campaignTypes, createCampaign, listCampaign, updateCampaign } from '../../../api/campaign.api';
import { listAdvertising } from '../../../api/advertising.api';
import { useParams } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IconButton } from '@mui/material';
import QRCode from 'react-qr-code';
import {url as urlAxios} from '../../../config/Axios' ;
import 'moment/locale/es-mx';
import axios from 'axios';

const labelStatusCampaign = (text) => {
  if (text === 'active') {
    return 'Activo';
  }
  return 'Inactivo';
};

let center = {
  lat: 14.0839154,
  lng: -87.2049727
};

const containerStyle = {
  width: '100%',
  height: '350px'
};

const AddOrEditModal = ({
  showAddModal,
  onClose,
  handleSubmit,
  isEdit,
  elementEdit,
  centerReact,
  setCenterReact,
  selectedType,
  setSelectedType,
  activateGeolocation,
  setActivateGeolocation,
  urlElement,
  loadingSubmit,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCo9Q4n5K1eCO8p2y1T1l0_Hdafb4WNNTE"
  });
  const [map, setMap] = React.useState(null)
  const [url, setUrl] = React.useState(elementEdit?.url);
  const [address, setAddress] = React.useState('');
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  useEffect(() => {
    setUrl(elementEdit?.url);
  }, [elementEdit?.url]);

  return (
    <Dialog open={showAddModal} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{isEdit ? 'Editar' : 'Nueva'} Campaña</DialogTitle>
      <DialogContent>
        <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-main-container">
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Título
                </Typography>
              </Box>
              <Input inputProps={{ maxLength: 200 }} className="full-width" id="title" name="title" placeholder="Villa navideña 2020..." defaultValue={elementEdit.title} />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Estatus
                </Typography>
              </Box>
              <TextField
                label="Estatus"
                select
                fullWidth
                name="status"
                defaultValue={elementEdit?.status}
              >
                <MenuItem value="active">
                  Público
                </MenuItem>
                <MenuItem value="inactive">
                  Privado
                </MenuItem>
              </TextField>
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Tipo
                </Typography>
              </Box>
              <Autocomplete
                disablePortal
                id="advertising"
                // getOptionLabel={option => (option.label || selectedType)}
                isOptionEqualToValue={(option, value) => (option.label === value)}
                options={campaignTypes}
                renderInput={(params) => <TextField {...params} />}
                defaultValue={selectedType}
                onChange={(e, value) => {
                  if (value?.name) {
                    setSelectedType(value.name);
                  }
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      {option.label}
                    </li>
                  )
                }}
              />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Archivo Multimedia
                </Typography>
                {(isEdit && elementEdit?.media) && (
                  <a
                    href={`${urlAxios}api/campaign/lofty/file/lofty/local/${elementEdit.media}`}
                    className="btn btn-transparent btn-sm"
                    target="_blank"
                  >
                    <CloudDownloadIcon />
                  </a>
                )}
                {
                  elementEdit ? (
                    <div>
                      {
                        elementEdit.type === 'image' && (
                          <img
                            style={{
                              width: '80%'
                            }}
                            alt='imagen'
                            src={`${urlAxios}api/campaign/lofty/file/lofty/local/${elementEdit.media}`}
                          />
                        )
                      }
                      {
                        (elementEdit.type === 'video' || elementEdit.type === 'qr') && (
                          <video
                            style={{
                              width: '80%'
                            }}
                            controls
                            src={`${urlAxios}api/campaign/lofty/file/lofty/local/${elementEdit.media}`}
                          />
                        )
                      }
                    </div>
                  ) : <></>
                }
              </Box>
              <Input id="media" name="media" placeholder="Archivo Multimedia" type="file" />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Duración (Mínimo de 20 segundos)
                </Typography>
              </Box>
              <Input type="number" inputProps={{ maxLength: 100, step: "1", min: 20 }} className="full-width" id="duration" name="duration" placeholder="20, 40, 120..." defaultValue={elementEdit.duration} />
            </div>
            {
              selectedType === 'qr' ? (
                <div className="form-input-container">
                  <Box mb={1} ml={0.5} width={"100%"}>
                    <Typography component="label" variant="subtitle1" fontWeight="bold">
                      URL
                    </Typography>
                  </Box>
                  <Input
                    type="url" inputProps={{ maxLength: 500, step: "1", min: 8 }}
                    className="full-width" id="url" name="url"
                    placeholder="http://dicipgo.com" value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              ) : <></>
            }
            <div className="form-input-container">
              <Box mb={1} ml={0.5}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Agregar Geolocalización
                </Typography>
              </Box>
              <div className="form-check geo-checkbox">
                <label className="form-check-label checkbox-text">
                  <input type="checkbox" className="form-check-input" defaultChecked={activateGeolocation} onClick={(e) => {setActivateGeolocation(e.target.checked)}} />
                  <i className="input-helper"></i>
                  Geolocalización
                </label>
              </div>
            </div>
          </div>
          {activateGeolocation && (
            <div>
              <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                id="form-address"
              >
                <div className='w-75'>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    placeholder='Ingrese una dirección'
                    style={{width: '100%'}}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-primary"
                  type='button'
                  onClick={async () => {
                    try {
                      const res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                        params: {
                          address,
                          key: 'AIzaSyCo9Q4n5K1eCO8p2y1T1l0_Hdafb4WNNTE',
                        }
                      });
                      console.log('address', res.data);
                      const { lat, lng } = res.data.results[0].geometry.location;
                      setCenterReact({
                        lat, lng
                      });
                    } catch (error) {
                      console.log(error);
                      toastr.error('No se encontro la dirección')
                    }
                  }}
                >
                  Buscar
                </button>
              </div>
              <br />
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  maxZoom: 17,
                }}
                onCenterChanged={(e) => {
                  const newCenter = {
                    lat: map?.center?.lat?.() || center.lat,
                    lng: map?.center?.lng?.() || center.lng,
                  };
                  setCenterReact({ ...newCenter });
                }}
              >
                <Marker
                  position={centerReact}
                />
              </GoogleMap>
            </div>
          )}
        </form>
        {
          (selectedType.name === 'qr' || elementEdit?.type === 'qr') && url && isEdit ? (
            <div>
              <QRCode value={elementEdit.domainqr} />
            </div>
          ) : <></>
        }
      </DialogContent>
      <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
        <button className="btn btn-primary" form="element-form" type="submit">
          <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
            {
              loadingSubmit ? (
                <>
                  {'Procesando ...'}
                </>
              ) : (
                <>
                  {isEdit ? 'EDITAR' : 'AGREGAR'}
                </>
              )
            }
          </Typography>
        </button>
      </DialogActions>
    </Dialog>
  )
}

const Campaign = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [centerReact, setCenterReact] = useState(center);
  const [counterPage, setCounterPage] = useState(1);
  const [ads, setAds] = useState({});
  const [selectedType, setSelectedType] = useState(campaignTypes[0].name);
  const [activateGeolocation, setActivateGeolocation] = useState(false);
  const [urlElement, setUrlElement] = useState('');
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  let { advertisingId } = useParams();

  const loadElements = async () => {
    try {
      const res = await listCampaign({ token, advertisingId, page: currentPage, count: limitData });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
      const reslist = await listAdvertising({ token });
      const data = reslist.data?.find((item) => item._id === advertisingId);
      if (data) {
        setAds(data);
      }
    } catch (error) {
      console.log(error);
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listCampaign({ token, advertisingId, page: value, count: limitData });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listCampaign({ token, advertisingId, page: 1, count: limiter });
      setCampaigns(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      advertisingId,
      title: e.target.title.value,
      type: selectedType,
      media: e.target.media.files[0],
      duration: e.target.duration.value,
      urlContact: e.target.url?.value,
      latitude: activateGeolocation ? centerReact.lat : null,
      longitude: activateGeolocation ? centerReact.lng : null,
      status: e.target.status.value,
    };
    if (loadingSubmit) {
      toastr.error('Se esta procesando la petición');
      return;
    }
    try {
      let response;
      setLoadingSubmit(true);
      if (isEdit) {
        response = await updateCampaign({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createCampaign({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
    setLoadingSubmit(false);
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
    setActivateGeolocation(false);
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
    const data = campaignTypes.find((item) => element.type === item.name);
    setSelectedType(data.name);
    setUrlElement(element.url);;
    const newCenter = {
      lat: element.latitude || center.lat,
      lng: element.longitude || center.lng,
    }
    center = newCenter;
    setCenterReact(newCenter);
    if (element.latitude && element.longitude) {
      setActivateGeolocation(true);
    }
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  const onCloseStateEdit = () => {
    setElementEdit(false);
    setUrlElement('');
    setSelectedType(campaignTypes[0].name);
    setIsEdit(false);
    setShowAddModal(false);
  }

  return (
    <div>
      <div>
        <IconButton
          onClick={() => {
            navigate('/advertising');
          }}
        >
          <span className="material-symbols-outlined">
            arrow_back
          </span>
        </IconButton>
      </div>
      <div
        style={{
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <h2
          style={{
            textAlign: 'center'
          }}
        >
          {ads.title}
        </h2>
      </div>
      <div className="section-title-container">
        <h4>
          Campañas de Publicidad
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Título</th>
                <th>Tipo de archivo</th>
                <th>Duración</th>
                <th>Estatus</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                  </td>
                  <td>{element.title}</td>
                  <td>{campaignTypes?.find?.(el => el.name === element.type)?.label}</td>
                  <td>{element.duration}</td>
                  <td>{labelStatusCampaign(element.status)}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        // setShowAddModal={setShowAddModal}
        onClose={() => onCloseStateEdit()}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        centerReact={centerReact}
        setCenterReact={setCenterReact}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        activateGeolocation={activateGeolocation}
        setActivateGeolocation={setActivateGeolocation}
        urlElement={urlElement}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Campaign);
