import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { logout } from "../../api/user.api";
import { deletetoken } from "../../actions/auth.actions";

const Sidebar = ({ token, deletetokenApp }) => {
  const { pathname } = useLocation();
  const logoutHandler = () => {
    logout({token});
    deletetokenApp();
  }
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item navbar-brand-mini-wrapper">
          <Link
            className="nav-link navbar-brand brand-logo-mini"
            to="/dashboard"
          >
            <img
              src={'/logo_dicipgo.png'}
              alt="logo"
              className="max-width-100"
            />
          </Link>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>Etadísticas</Trans>
          </span>
        </li>
        <li
          className={
            pathname === '/dashboard' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to="/dashboard">
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
            <div className="material-symbols-outlined nav-icon">query_stats</div>
          </Link>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>Administración</Trans>
          </span>
        </li>
        <li
          className={
            pathname === '/users' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/users', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Usuarios</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">account_circle</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/companies' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/companies', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Empresas</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">store</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/advertising' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/advertising', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Publicidad</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">campaign</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/vehicles' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/vehicles', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Vehículos</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">directions_car</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/tablets' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/tablets', search: '?page=1&count=10'}} >
            <span className="menu-title">
              <Trans>Tablets</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">tablet_mac</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/plans' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/plans', search: '?page=1&count=10'}} >
            <span className="menu-title">
              <Trans>Planes</Trans>
            </span>
            <div className="material-symbols-outlined nav-icon">attach_money</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/referrals' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/referrals', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Referidos</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">account_circle</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/rushhour' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/rushhour', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Hora Pico</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">account_circle</div>
          </Link>
        </li>
        <li
          className={
            pathname === '/tabletpervehicles' ? 'nav-item active' : 'nav-item'
          }
        >
          <Link className="nav-link nav-link-separator" to={{pathname: '/tabletpervehicles', search: '?page=1&count=10'}}>
            <span className="menu-title">
              <Trans>Tablet por Vehiculo</Trans>
            </span>
            <div className="material-icons-outlined nav-icon">tablet_mac</div>
          </Link>
        </li>
        <li className="nav-item nav-category">
          <button className="btn btn-dark p-2 nav-link cursor-pointer mt-2" onClick={logoutHandler}>
            <Trans>Cerrar Sesión</Trans>
          </button>
        </li>
      </ul>
    </nav>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
