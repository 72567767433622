import axios from '../config/Axios';
export const url = '/api/advertising';

export const createAdvertising = ({
  token,
  title,
  description,
  productname,
  startDate,
  endDate,
  idPlan,
  idCompany
}) => new Promise((resolve, reject) => {
  if (
    title &&
    description &&
    productname &&
    startDate &&
    endDate
  ) {
    axios.post(
      `${url}/create/basic/`,
      {
        title,
        description,
        productname,
        startDate,
        endDate,
        idPlan,
        idCompany,
      },
      {
        headers: {
          AuthorizationApp: token,
        },
      }).then((res) => {
        const { data } = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch((error) => reject(error));
  } else if (!title) {
    reject({
      status: 'error',
      info: 'Título definido',
    });
  } else if (!description) {
    reject({
      status: 'error',
      info: 'Descripción no definida',
    });
  } else if (!productname) {
    reject({
      status: 'error',
      info: 'Nombre de producto no definido',
    });
  } else if (!startDate) {
    reject({
      status: 'error',
      info: 'Fecha de inicio de campaña no definido',
    });
  } else if (!endDate) {
    reject({
      status: 'error',
      info: 'Fecha de finalización de campaña no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const updateAdvertising = ({
  token,
  _id,
  title,
  description,
  productname,
  startDate,
  endDate,
  idCompany,
  driver,
  idRushHour,
}) => new Promise((resolve, reject) => {
  if (
    title &&
    description &&
    productname &&
    startDate &&
    endDate
  ) {
    axios.put(
      `${url}/update/basic/${_id}`,
      {
        title,
        description,
        productname,
        startDate,
        endDate,
        idCompany,
        idRushHour,
        driver,
      },
      {
        headers: {
          AuthorizationApp: token,
        },
      }).then((res) => {
        const { data } = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch((error) => reject(error));
  } else if (!title) {
    reject({
      status: 'error',
      info: 'Título definido',
    });
  } else if (!description) {
    reject({
      status: 'error',
      info: 'Descripción no definida',
    });
  } else if (!productname) {
    reject({
      status: 'error',
      info: 'Nombre de producto no definido',
    });
  } else if (!startDate) {
    reject({
      status: 'error',
      info: 'Fecha de inicio de campaña no definido',
    });
  } else if (!endDate) {
    reject({
      status: 'error',
      info: 'Fecha de finalización de campaña no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Error al guardar el dato',
    });
  }
});

export const listAdvertising = ({ token, page, count }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list/basic/`, {
      headers: {
        AuthorizationApp: token,
      },
      params: {
        page,
        count,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const totalAdvertisign = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/total`, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token es requerido'
    });
  }
});

export const totalCompanyAdvertisign = ({
  token, idCompany, initialDate, endDate
}) => new Promise((resolve, reject) => {
  if (token && idCompany) {
    axios.post(`${url}/total/company/${idCompany}`, {
      initialDate, endDate,
    }, {
      headers: {
        AuthorizationApp: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Compañia no definido'
    });
  }
});

export const getOneAdvertising = ({
  id, token,
}) => new Promise((resolve, reject) => {
  if (id && token) {
    axios.get(`${url}/one/basic/${id}`, {
      headers: {
        AuthorizationApp: token
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'No estas autenticado'
    })
  }
});
