/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
} from "@mui/material";
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { listTablets } from '../../../api/tablet.api';
import 'moment/locale/es-mx';
import { listUsers } from '../../../api/user.api';
import { createRushHour, deleteRushHour, listRushHour, updateRushHour } from '../../../api/rushhour.api';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
}) => (
  <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
    <DialogTitle>{isEdit ? 'Editar' : 'Nueva'} Hora Pico</DialogTitle>
    <DialogContent>
      <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="form-main-container">
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                titulo
              </Typography>
            </Box>
            <Input className="full-width" id="title" name="title" placeholder="Hora pico" defaultValue={elementEdit.title}
              type="text"
              inputProps={{
                maxLength: 15,
                step: "1"
              }}
            />
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Hora Inicial
              </Typography>
            </Box>
            <TextField className="full-width" defaultValue={elementEdit.initialHour} select name="initialHour" type="initialHour" label="Hora:" variant="standard">
              <MenuItem value={'0'}>0:00</MenuItem>
              <MenuItem value={'1'}>1:00</MenuItem>
              <MenuItem value={'2'}>2:00</MenuItem>
              <MenuItem value={'3'}>3:00</MenuItem>
              <MenuItem value={'4'}>4:00</MenuItem>
              <MenuItem value={'5'}>5:00</MenuItem>
              <MenuItem value={'6'}>6:00</MenuItem>
              <MenuItem value={'7'}>7:00</MenuItem>
              <MenuItem value={'8'}>8:00</MenuItem>
              <MenuItem value={'9'}>9:00</MenuItem>
              <MenuItem value={'10'}>10:00</MenuItem>
              <MenuItem value={'11'}>11:00</MenuItem>
              <MenuItem value={'12'}>12:00</MenuItem>
              <MenuItem value={'13'}>13:00</MenuItem>
              <MenuItem value={'14'}>14:00</MenuItem>
              <MenuItem value={'15'}>15:00</MenuItem>
              <MenuItem value={'16'}>16:00</MenuItem>
              <MenuItem value={'17'}>17:00</MenuItem>
              <MenuItem value={'18'}>18:00</MenuItem>
              <MenuItem value={'19'}>19:00</MenuItem>
              <MenuItem value={'20'}>20:00</MenuItem>
              <MenuItem value={'21'}>21:00</MenuItem>
              <MenuItem value={'22'}>22:00</MenuItem>
              <MenuItem value={'23'}>23:00</MenuItem>
            </TextField>
          </div>
          <div className="form-input-container">
            <Box mb={1} ml={0.5} width={"100%"}>
              <Typography component="label" variant="subtitle1" fontWeight="bold">
                Hora Final
              </Typography>
            </Box>
            <TextField className="full-width" defaultValue={elementEdit.endHour} select name="endHour" type="endHour" label="Hora:" variant="standard">
              <MenuItem value={'0'}>0:00</MenuItem>
              <MenuItem value={'1'}>1:00</MenuItem>
              <MenuItem value={'2'}>2:00</MenuItem>
              <MenuItem value={'3'}>3:00</MenuItem>
              <MenuItem value={'4'}>4:00</MenuItem>
              <MenuItem value={'5'}>5:00</MenuItem>
              <MenuItem value={'6'}>6:00</MenuItem>
              <MenuItem value={'7'}>7:00</MenuItem>
              <MenuItem value={'8'}>8:00</MenuItem>
              <MenuItem value={'9'}>9:00</MenuItem>
              <MenuItem value={'10'}>10:00</MenuItem>
              <MenuItem value={'11'}>11:00</MenuItem>
              <MenuItem value={'12'}>12:00</MenuItem>
              <MenuItem value={'13'}>13:00</MenuItem>
              <MenuItem value={'14'}>14:00</MenuItem>
              <MenuItem value={'15'}>15:00</MenuItem>
              <MenuItem value={'16'}>16:00</MenuItem>
              <MenuItem value={'17'}>17:00</MenuItem>
              <MenuItem value={'18'}>18:00</MenuItem>
              <MenuItem value={'19'}>19:00</MenuItem>
              <MenuItem value={'20'}>20:00</MenuItem>
              <MenuItem value={'21'}>21:00</MenuItem>
              <MenuItem value={'22'}>22:00</MenuItem>
              <MenuItem value={'23'}>23:00</MenuItem>
            </TextField>
          </div>
        </div>
      </form>
    </DialogContent>
    <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
      <button className="btn btn-primary" form="element-form" type="submit">
        <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
          {isEdit ? 'EDITAR' : 'AGREGAR'}
        </Typography>
      </button>
    </DialogActions>
  </Dialog>
)

const RushHour = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));
  const [userId, setUserId] = useState();
  const [infoUser, setInfoUser] = useState([]);

  const getUserInfo = (_id) => {
    try {
      const userInfoReq = infoUser.find((index) => (index._id === _id));
      return userInfoReq;
    } catch (error) {
      return null;
    }
  }

  const getUserName = (_id) => {
    try {
      const findUser = infoUser.find((index) => (index._id === _id));
      return findUser.loftyName;
    } catch (error) {
      return null;
    }
  }

  const loadElements = async () => {
    try {
      const res = await listRushHour({ token, page: currentPage, count: limitData });
      setUsers(res.data);
      if (res.paginateInfo) {
        setCounterPage(Math.ceil(res.paginateInfo.totalPages));
      }
    } catch (error) {
      console.log('error', error);
      toastr.error('Error al obtener los datos');
    }
  }


  const loadUsers = async () => {
    try {
      const resUserdata = await listUsers({ token, page: 1, count: 0 });
      setInfoUser(resUserdata.data);
    } catch (error) {
      console.log(error)
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({ page: value, count: limitData });
      const res = await listTablets({ token, page: value, count: limitData });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({ page: 1, count: limiter });
      // setCurrentPage(1);
      const res = await listTablets({ token, page: 1, count: limiter });
      setUsers(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      title: e.target.title.value,
      initialHour: e.target.initialHour?.value,
      endHour: e.target.endHour?.value
    };
    try {
      let response;
      if (isEdit) {
        response = await updateRushHour({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        response = await createRushHour({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  useEffect(() => {
    loadElements();
    loadUsers();
  }, [])

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Tablets
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>titulo</th>
                <th>Hora Inicial</th>
                <th>Hora Final</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {users.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={async () => {
                      try {
                        await deleteRushHour({token});
                        loadElements();
                      } catch (error) {
                        // error
                      }
                    }}>
                      Eliminar
                    </button>
                  </td>
                  <td>{element.title}</td>
                  <td>{element.initialHour}</td>
                  <td>{element.endHour}</td>
                  <td>{formatDateMoment(element.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        userData={infoUser}
        setUserId={setUserId}
        selectedUser={getUserInfo(elementEdit.userId)}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(RushHour);
