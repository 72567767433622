import axios from '../config/Axios';

export const url = '/api/eventscampaign';

export const countPauseEvents = ({
  token, idAdverising,
}) => new Promise((resolve, reject) => {
  if (token && idAdverising) {
    axios.get(`${url}/count/pause/${idAdverising}`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data)
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros necesarios'
    });
  }
});

export const countNextEvents = ({
  token, idAdverising,
}) => new Promise((resolve, reject) => {
  if (token && idAdverising) {
    axios.get(`${url}/count/next/${idAdverising}`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data)
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros necesarios'
    });
  }
});

export const countBackEvents = ({
  token, idAdverising,
}) => new Promise((resolve, reject) => {
  if (token && idAdverising) {
    axios.get(`${url}/count/back/${idAdverising}`, {
      headers: {
        AuthorizationApp: token,
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data)
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Parametros necesarios'
    });
  }
});