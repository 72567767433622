/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Input,
  TextField,
  MenuItem,
} from "@mui/material";
import Select from '@mui/material/Select';
import './Advertising.css';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { createAdvertising, listAdvertising, updateAdvertising } from '../../../api/advertising.api';
import { listPlans } from '../../../api/plans.api';
import { listCompany } from '../../../api/company.api';
import { Link } from 'react-router-dom';
import 'moment/locale/es-mx';
import { listRushHour } from '../../../api/rushhour.api';

const AddOrEditModal = ({
  showAddModal,
  setShowAddModal,
  handleSubmit,
  isEdit,
  elementEdit,
  token,
  rushHour
}) => {
  const [plans, setPlans] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(elementEdit.idCompany || '');
  const [rush, setRush] = useState([]);
  const handleData = async () => {
    try {
      const res = await listPlans({token});
      setPlans(res.data);
      const resCompany = await listCompany({token});
      setCompanies(resCompany.data);
    } catch (error) {
      // code
    }
  }
  useEffect(() => {
    handleData();
  }, []);
  useEffect(() => {
    if (elementEdit.idRushHour) {
      if (typeof elementEdit.idRushHour === 'string') {
        setRush(elementEdit.idRushHour.split(','));
      } else {
        setRush(elementEdit.idRushHour);
      }
    }
  }, []);
  return (
    <Dialog open={showAddModal} onClose={() => setShowAddModal(false)} fullWidth maxWidth="lg">
      <DialogTitle>{isEdit ? 'Editar' : 'Nuevo'} Paquete de Publicidad</DialogTitle>
      <DialogContent>
        <form id="element-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="form-main-container">
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Título
                </Typography>
              </Box>
              <Input inputProps={{ maxLength: 200 }} className="full-width" id="title" name="title" placeholder="Harina el sabrosal..." defaultValue={elementEdit.title} />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Descripción
                </Typography>
              </Box>
              <Input inputProps={{ maxLength: 1000 }} className="full-width" id="description" name="description" placeholder="La mejor harina, con el sabor más delicioso..." defaultValue={elementEdit.description} />
            </div>
            {
              !isEdit ? (
                <div className="form-input-container">
                  <Box mb={1} ml={0.5} width={"100%"}>
                    <Typography component="label" variant="subtitle1" fontWeight="bold">
                      Plan
                    </Typography>
                  </Box>
                  <Select
                    className='full-width'
                    name='idPlan'
                    onChange={(e) => {
                      setSelectedPlan(e.target.value);
                    }}
                    value={selectedPlan}
                  >
                    {
                      plans.map((item) => (
                        <MenuItem value={item._id} >
                          {item.label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
              ) : <></>
            }
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Conductores
                </Typography>
              </Box>
              <Input type='number' inputProps={{ maxLength: 1000 }} className="full-width" id="driver" name="driver" placeholder="1" defaultValue={elementEdit.driver || 1} />
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Empresa
                </Typography>
              </Box>
              <Select
                className='full-width'
                name='idCompany'
                onChange={(e) => {
                  setSelectedCompany(e.target.value);
                }}
                value={selectedCompany}
              >
                {
                  companies.map((item) => (
                    <MenuItem value={item._id} >
                      {item.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Hora Pico
                </Typography>
              </Box>
              <Select
                className='full-width'
                name='idRushHour'
                onChange={(e) => {
                  setRush(e.target.value);
                }}
                value={rush}
                multiple
              >
                {
                  rushHour.map((item) => (
                    <MenuItem value={item._id} >
                      {item.title}
                    </MenuItem>
                  ))
                }
              </Select>
            </div>
            <div className="form-input-container">
              <Box mb={1} ml={0.5} width={"100%"}>
                <Typography component="label" variant="subtitle1" fontWeight="bold">
                  Nombre de Producto
                </Typography>
              </Box>
              <Input inputProps={{ maxLength: 1000 }} className="full-width" id="productname" name="productname" placeholder="Harina el comal" defaultValue={elementEdit.productname} />
            </div>
            <div className="form-input-container">
                <Box mb={1} ml={0.5} width={"100%"}>
                  <Typography component="label" variant="subtitle1" fontWeight="bold">
                    Fecha de Inicio
                  </Typography>
                </Box>
                <Input className="full-width" id="startDate" name="startDate" type="date"
                  defaultValue={
                    moment(elementEdit.startDate).format('YYYY-MM-DD')
                  }
                />
              </div>
              <div className="form-input-container">
                <Box mb={1} ml={0.5} width={"100%"}>
                  <Typography component="label" variant="subtitle1" fontWeight="bold">
                    Fecha de Finalización
                  </Typography>
                </Box>
                <Input className="full-width" id="endDate" name="endDate"
                  type="date"
                  defaultValue={
                    moment(elementEdit.endDate).format('YYYY-MM-DD')
                  }
                />
              </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
        <button className="btn btn-primary" form="element-form" type="submit">
          <Typography variant="button" fontWeight="bold" textTransform="capitalize" color="white">
            {isEdit ? 'EDITAR' : 'AGREGAR'}
          </Typography>
        </button>
      </DialogActions>
    </Dialog>
  );
}

const Advertising = ({ token }) => {
  moment.locale('es-mx');
  let [searchParams, setSearchParams] = useSearchParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [elementEdit, setElementEdit] = useState(false);
  const [advertisings, setAdvertisings] = useState([]);
  const [counterPage, setCounterPage] = useState(1);
  const [rushHour, setRushHour] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [limitData, setLimitData] = useState(10);
  const currentPage = parseInt(searchParams.get('page'));
  const limitData = parseInt(searchParams.get('count'));

  const loadElements = async () => {
    try {
      const res = await listAdvertising({ token, page: currentPage, count: limitData });
      setAdvertisings(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
      const resRush = await listRushHour({token});
      setRushHour(resRush.data);
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const seekNewData = async (value) => {
    try {
      // setCurrentPage(page);
      setSearchParams({page: value, count: limitData});
      const res = await listAdvertising({ token, page: value, count: limitData });
      setAdvertisings(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const stateNewLimitPage = async (limiter) => {
    try {
      setSearchParams({page: 1, count: limiter});
      // setCurrentPage(1);
      const res = await listAdvertising({ token, page: 1, count: limiter });
      setAdvertisings(res.data);
      setCounterPage(Math.ceil(res.paginateInfo.totalPages));
    } catch (error) {
      toastr.error('Error al obtener los datos');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newElement = {
      title: e.target.title.value,
      description: e.target.description.value,
      productname: e.target.productname.value,
      startDate: new Date(e.target.startDate.value),
      endDate: new Date(e.target.endDate.value),
      driver: e.target?.driver?.value,
      idRushHour: e.target.idRushHour.value ? (
        e.target.idRushHour.value.split(',')
      ) : [],
    };
    try {
      let response;
      if (isEdit) {
        console.log(elementEdit);
        response = await updateAdvertising({ token, ...newElement, _id: elementEdit._id });
        setShowAddModal(false);
      } else {
        newElement.idPlan = e.target.idPlan.value;
        response = await createAdvertising({ token, ...newElement });
      }
      toastr.success(response?.info);
      loadElements();
      document.querySelector('#element-form').reset();
    } catch (error) {
      console.log(error)
      toastr.error(error?.info || error?.response?.data?.info);
    }
  }

  const showAdd = () => {
    setShowAddModal(true);
    setIsEdit(false);
    setElementEdit({});
  }

  const showEdit = (element) => {
    console.log(element, 'elemento al boton de editar');
    setShowAddModal(true);
    setIsEdit(true);
    setElementEdit(element);
  }

  useEffect(() => {
    loadElements();
  }, [])

  const formatDateMoment = (createdDate) => {
    return moment(createdDate).format('YYYY MMMM DD, h:mm:ss a')
  }

  return (
    <div>
      <div className="section-title-container">
        <h4>
          Publicidad
        </h4>
        <button className="btn btn-primary" onClick={showAdd}>
          Agregar
        </button>
      </div>
      <div className="card card-body">
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Acciones</th>
                <th>Título</th>
                <th>Descripción</th>
                <th>Nombre de Producto</th>
                <th>Plan</th>
                <th>Fecha Inicial</th>
                <th>Fecha de Finalización</th>
                <th>Creado en</th>
              </tr>
            </thead>
            <tbody>
              {advertisings.map(element => (
                <tr>
                  <td className="text-danger">
                    <button className="btn btn-primary btn-rounded btn-sm" onClick={() => showEdit(element)}>
                      Editar
                    </button>
                    <Link className="btn btn-primary btn-rounded btn-sm" to={{pathname: `campaign/${element._id}`, search: '?page=1&count=10'}}>
                      Campañas
                    </Link>
                    <Link className="btn btn-primary btn-rounded btn-sm" to={{pathname: `metrics/${element._id}`}}>
                      Metricas
                    </Link>
                  </td>
                  <td>{element.title}</td>
                  <td>{element.description}</td>
                  <td>{element.productname}</td>
                  <td>{element.namePlan}</td>
                  <td>{new Date(element.startDate).toLocaleDateString('es-MX')}</td>
                  <td>{new Date(element.endDate).toLocaleDateString('es-MX')}</td>
                  <td>{formatDateMoment(element.loftyCreatedAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        {currentPage > 1 && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage - 1)}>
            Previo
          </button>
        )
        }
        <div style={{ margin: '5px' }}>
          Pag: {currentPage} / {counterPage}
        </div>
        {currentPage < counterPage && (
          <button className="btn btn-primary btn-rounded btn-sm" style={{ margin: '5px' }} onClick={() => seekNewData(currentPage + 1)}>
            Siguiente
          </button>
        )}
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TextField defaultValue={limitData} onChange={(e) => stateNewLimitPage(e.target.value)} select name="rowsPerPage" type="rowsPerPage" label="Datos por Pagina:" variant="standard">
          <MenuItem value={10} key={10}>
            10 por Pagina
          </MenuItem>
          <MenuItem value={20} key={20}>
            20 por Pagina
          </MenuItem>
          <MenuItem value={50} key={50}>
            50 por Pagina
          </MenuItem>
        </TextField>
      </div>
      <AddOrEditModal
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        elementEdit={elementEdit}
        token={token}
        rushHour={rushHour}
      />
    </div>
  )
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Advertising);
